export const footerMenu = {
  Company: {
    href: '/',
    submenu: {
      aboutUs: {
        href: '/',
      },
      legalInfo: {
        href: '/',
      },
      pressReleases: {
        href: '/',
      },
    },
  },
  forUser: {
    href: '/',
    submenu: {
      job: {
        href: '/',
      },
      realEstate: {
        href: '/',
      },
      services: {
        href: '/',
      },
    },
  },
  ourProjects: {
    href: '/',
    submenu: {
      delivery: {
        href: '/',
      },
      jobsAndVacancies: {
        href: '/',
      },
      carberri: {
        href: '/',
      },
    },
  },
  help: {
    href: '/',
    submenu: {
      createPost: {
        href: '/doc/create_post',
        id: '6m02JT25D0rXHFGLys01dJ',
      },
      howToPromote: {
        href: '/',
      },
      zotzonWhatItIs: {
        href: '/',
      },
      services: {
        href: '/',
      },
    },
  },
  socialNetworks: {
    href: '/',
    submenu: {
      Instagram: {
        href: '/',
      },
      Facebook: {
        href: '/',
      },
      VK: {
        href: '/',
      },
    },
  },
};
