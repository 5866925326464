import React from 'react';
import { useRouter } from 'next/router';

import Link from 'next/link';

import classNames from 'classnames';
import styles from './link.module.css';

const SuperLink = ({ className, href, children, locale, ...rest }) => {
  const router = useRouter();

  if (!href) {
    return <>{children}</>;
  }

  //https://stackoverflow.com/a/58482041
  // important to have nested <a> here to let user open links in new tabs
  // prettier-ignore
  return href.indexOf('http') === 0
    ? <a href={href} className={classNames(styles.link, className)} {...rest}>{children}</a>
    : <Link href={href} passHref locale={locale ?? router.locale} {...rest}>
        <a href={href} className={classNames(styles.link, className)} {...rest}>{children}</a>
      </Link>;
};

export default SuperLink;
