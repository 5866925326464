import React from 'react';
import NextHead from 'next/head';
import { string } from 'prop-types';

const defaultDescription = 'Sell in one #, buy in one click';
const defaultKeywords = 'ZotZon';
const defaultOGURL = '';
const defaultOGImage = '';

//prettier-ignore
const Head = (props) =>
  <NextHead>
    <meta charSet='utf-8' />
    <title>{props.title || 'ZotZon'}</title>
    {/*<meta*/}
    {/*  name='viewport'*/}
    {/*  content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no'*/}
    {/*/>*/}
      <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.0" />
    <meta name='description' content={props.description || defaultDescription} />
    <meta name='keywords' content={props.keywords || defaultKeywords} />
    <link rel='manifest' href='/manifest.json' />

    <link rel='icon' sizes='192x192' href='/images/icons/icon-192x192.png' />

    <link rel='apple-touch-icon' sizes='57x57' href='/images/icons/apple-icon-57x57.png' />
    <link rel='apple-touch-icon' sizes='60x60' href='/images/icons/apple-icon-60x60.png' />
    <link rel='apple-touch-icon' sizes='72x72' href='/images/icons/apple-icon-72x72.png' />
    <link rel='apple-touch-icon' sizes='76x76' href='/images/icons/apple-icon-76x76.png'/>
    <link rel='apple-touch-icon' sizes='114x114' href='/images/icons/apple-icon-114x114.png' />
    <link rel='apple-touch-icon' sizes='120x120' href='/images/icons/apple-icon-120x120.png' />
    <link rel='apple-touch-icon' sizes='144x144' href='/images/icons/apple-icon-144x144.png' />
    <link rel='apple-touch-icon' sizes='152x152' href='/images/icons/apple-icon-152x152.png' />
    <link rel='apple-touch-icon' sizes='167x167' href='/images/icons/images/icons/apple-icon-167.jpg' />
    <link rel='apple-touch-icon' sizes='180x180' href='/images/icons/apple-icon-180x180.png' />
    <link rel='icon' type='image/png' sizes='192x192'  href='/images/icons/android-icon-192x192.png' />
    <link rel='icon' type='image/png' sizes='32x32' href='/images/icons/favicon-32x32.png' />
    <link rel='icon' type='image/png' sizes='96x96' href='/images/icons/favicon-96x96.png' />
    <link rel='icon' type='image/png' sizes='16x16' href='/images/icons/favicon-16x16.png' />

    <meta name='msapplication-TileColor' content='#ffffff' />
    <meta name='msapplication-TileImage' content='/images/icons/ms-icon-144x144.png' />

    <link rel='apple-touch-icon' sizes='120x120' href='/images/icons/apple-icon-120.jpg' />
    <link rel='apple-touch-icon' sizes='152x152' href='/images/icons/apple-icon-152.jpg' />
    <link rel='apple-touch-icon' sizes='180x180' href='/images/icons/apple-icon-180.jpg' />

    <meta name='apple-mobile-web-app-capable' content='yes' />

    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-2048-2732.jpg' media='(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-2732-2048.jpg' media='(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-1668-2388.jpg' media='(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-2388-1668.jpg' media='(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-1668-2224.jpg' media='(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-2224-1668.jpg' media='(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-1536-2048.jpg' media='(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-2048-1536.jpg' media='(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-1242-2688.jpg' media='(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-2688-1242.jpg' media='(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-1125-2436.jpg' media='(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-2436-1125.jpg' media='(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-828-1792.jpg' media='(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-1792-828.jpg' media='(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-1242-2208.jpg' media='(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-2208-1242.jpg' media='(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-750-1334.jpg' media='(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-1334-750.jpg' media='(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-640-1136.jpg' media='(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
    <link rel='apple-touch-startup-image' href='/images/icons/apple-splash-1136-640.jpg' media='(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />

    <link rel='icon' href='/images/icons/favicon.ico' />

    <meta property='og:url' content={props.url || defaultOGURL} />
    <meta property='og:title' content={props.title || ''} />
    <meta property='og:description' content={props.description || defaultDescription} />
    <meta name='twitter:site' content={props.url || defaultOGURL} />
    <meta name='twitter:card' content='summary_large_image' />
    <meta name='twitter:image' content={props.ogImage || defaultOGImage} />
    <meta property='og:image' content={props.ogImage || defaultOGImage} />
    <meta property='og:image:width' content='1200' />
    <meta property='og:image:height' content='630' />
  </NextHead>

Head.propTypes = {
  title: string,
  description: string,
  url: string,
  ogImage: string,
};

export default Head;
