import { getAvatarVersionUrl } from 'lib/images';
import ZotzonImage from './ZotzonImage';

const ZotzonAvatar = (props) => {
  return (
    <ZotzonImage
      {...props}
      src={getAvatarVersionUrl({ url: props?.src, version: props?.version })}
      version={undefined}
    />
  );
};

export default ZotzonAvatar;
