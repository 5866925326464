import React, { useLayoutEffect, useState, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { categoryPath } from 'constants/path';
import qs from 'querystring';
import { Input, Popover, Button, Select, Divider, Radio } from 'antd';
import { DownOutlined, AppstoreOutlined } from '@ant-design/icons';
import locations from 'constants/locations.json';
import categories from 'constants/categories.json';
import styles from 'components/ant/css/styles.module.css';

const { Search } = Input;
const { Option } = Select;

const SearchInput = ({ show }) => {
  const router = useRouter();
  const { t } = useTranslation('common');
  const { slug, ...query } = useRouter().query;

  const [category, setCategory] = useState('');
  const [location, setLocation] = useState();
  const [searchText, setSearchText] = useState('');

  const [searchProp, setSearchProp] = useState(false);
  const [popupWidth, setPopupWidth] = useState(300);

  const onSubmit = (contains) => {
    const nextQuery = Object.assign(query, { contains }) ?? searchText;
    // console.log('gfgf', contains, category, location);
    const url = `${categoryPath({ slug: category == '' ? 'listing' : category, location: location ?? 'worldwide' })}${
      Object.keys(nextQuery).length !== 0 ? '?' + qs.encode(nextQuery) : ''
    }`;
    setSearchProp(false);
    contains && router.push(url);
    if (!contains) {
      router.push(categoryPath({ slug: category == '' ? 'listing' : category, location: location ?? 'worldwide' }));
    }
  };

  const onSearch = (value) => {
    // setSearchText(value);
    const tag = router.query?.tag ? `&tag=${encodeURIComponent(router.query?.tag.replaceAll('\n', ''))}` : '';
    router.push(`/search?t=${encodeURIComponent(value)}${tag}`);
    //onSubmit(value);
  };

  const onChangeSearch = (e) => {
    setSearchText(e.target.value);
    //console.log(e);
  };

  useEffect(() => {
    if (router?.query?.t) {
      setSearchText(router?.query?.t);
    }
  }, [router?.query?.t]);

  function handleChange(value) {
    setLocation(value);
    //console.log(`selected ${value}`);
  }

  const children = [];

  const locationList = locations.map((item) => {
    let title = router.locale === 'ru' ? item.nameRu : item.nameEn;
    children.push(
      <Option key={item.id} label={title} value={item.slug}>
        {title}
      </Option>,
    );
  });

  // const { categories } = useCategoriesContext();

  const options = [];
  options.push({ label: t('everywhere'), value: false });

  function onChange(e) {
    setCategory(e.target.value ?? false);
    // console.log('checked = ', e.target.value);
  }

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    // useLayoutEffect(() => {
    //   function updateSize() {
    //     setSize([window.innerWidth, window.innerHeight]);
    //   }
    //   window.addEventListener('resize', updateSize);
    //   updateSize();
    //   return () => window.removeEventListener('resize', updateSize);
    // }, []);
    return size;
  }

  const [width, height] = useWindowSize();

  const ref = useRef(null);
  useEffect(() => {
    //console.log('width', ref.current.offsetWidth);
    setPopupWidth(ref.current.offsetWidth - 30);
  }, [width]);

  const [radius, setRadius] = useState(0);

  const onChangeRadius = (e) => {
    //console.log('radio checked', e.target.value);
    setRadius(e.target.value);
  };

  return (
    <div
      style={{
        display: show || router.pathname == '/search' ? '' : 'none',
      }}
    >
      <Popover
        placement='bottomLeft'
        title={false}
        open={searchProp}
        trigger='click'
        content={
          <div
            style={{
              width: `${popupWidth}px`,
            }}
          >
            <div>
              <div>
                {t('City')}
                <Select
                  // mode='multiple'
                  showSearch
                  allowClear
                  className={styles.divSelectWidth}
                  placeholder={t('pleaseSelectCity')}
                  //defaultValue={['1']}
                  onChange={handleChange}
                  optionLabelProp='label'
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {children}
                </Select>
              </div>
              <Divider />
              <h4 className={styles.searchDivH4}> {t('category')}</h4>
              <Radio.Group options={options} defaultValue={['1']} onChange={onChange} />
              <Divider />
              {1 === 2 && (
                <div className={styles.divSearch}>
                  <h4 className={styles.searchDiv1H4}> Радиус поиска</h4>
                  <Radio.Group onChange={onChangeRadius} value={radius}>
                    <Radio value={0}>0 km</Radio>
                    <Radio value={10}>10 km</Radio>
                    <Radio value={25}>25 km</Radio>
                    <Radio value={50}>50 km</Radio>
                    <Radio value={100}>100 km</Radio>
                  </Radio.Group>
                </div>
              )}
            </div>
            <Button
              type='primary'
              className={styles.searchButton}
              onClick={() => {
                // console.log('1111',searchText)
                onSubmit(searchText);
              }}
            >
              {t('search')}
            </Button>
          </div>
        }
      >
        <div ref={ref} className={styles.divSearch1}>
          <Search
            name='contains'
            placeholder={t('searchPlaceholder')}
            onSearch={onSearch}
            onChange={onChangeSearch}
            autoComplete='off'
            value={searchText}
            className={styles.searchS}
          />
        </div>
      </Popover>
    </div>
  );
};

export default SearchInput;
