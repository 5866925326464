import pb, { GravityType, ResizeType } from '@bitpatty/imgproxy-url-builder';
import { getImageHost } from 'modules/request';

const IPHONE_WIDTH = 375;

const signature = {
  key: '5d14239c0c1a7b3eeb03a2e191e66dfb1a924b92138388480acac3f32e64b317', // TODO: move to ENV
  salt: '4a4d1d5c62b995fc6c5f1e1793b2d8a2f5fa438f7cae1987c1d765b2c05513d9', // TODO: move to ENV
};

const versions = {
  original: {
    width: 1080,
    height: 1080,
    fit: 'inside',
  },
  normal: {
    width: 640,
    height: 640,
    fit: 'fill-down',
  },
  tiny: {
    width: 254,
    height: 254,
    fit: 'fill-down',
  },
  inst: {
    width: 800,
    height: 1000,
    fit: 'fit',
  },
  inst_hor: {
    width: 1000,
    height: 600,
    fit: 'fit',
  },
  cover: {
    height: 1080,
    type: 'fit',
    width: 1080,
  },
  crop: {
    height: 500,
    type: 'crop',
    width: 500,
  },
};

// TODO: with this approach, we can't use the same image in different sizes
// therefore we can delete smaller versions and jsonb field from the database
export const getImageVersionUrl = ({ id, version }) => {
  // console.log('getImageVersionUrl: ', getImageHost(), id, version);
  if (id && version) {
    switch (version) {
      case 'original':
        return (
          getImageHost() +
          pb().build({
            path: `https://aws.zotzon.com/images/${id}/original.jpeg`,
            signature,
          })
        );
      case 'cover':
        return (
          getImageHost() +
          pb()
            .format('webp')
            .minHeight(IPHONE_WIDTH)
            .minWidth(IPHONE_WIDTH)
            .resize({
              height: versions[version].height,
              // type: versions[version].type, // https://github.com/imgproxy/imgproxy/blob/6f292443eafb2e39f9252175b61faa6b38105a7c/docs/generating_the_url.md#resizing-type
              width: versions[version].width,
            })
            .gravity({
              type: GravityType.CENTER,
            })
            .build({
              path: `https://aws.zotzon.com/images/${id}/original.jpeg`,
              signature,
            })
        );
      case 'normal':
        return (
          getImageHost() +
          pb() // TODO: use suitable domain name according to env and hostname
            .resize({
              type: versions[version].type, // https://github.com/imgproxy/imgproxy/blob/6f292443eafb2e39f9252175b61faa6b38105a7c/docs/generating_the_url.md#resizing-type
              width: versions[version].width,
              height: versions[version].height,
            })
            .build({
              path: `https://aws.zotzon.com/images/${id}/original.jpeg`,
              signature,
            })
        );
      case 'tiny':
        return (
          getImageHost() +
          pb() // TODO: use suitable domain name according to env and hostname
            .resize({
              type: versions[version].type, // https://github.com/imgproxy/imgproxy/blob/6f292443eafb2e39f9252175b61faa6b38105a7c/docs/generating_the_url.md#resizing-type
              width: versions[version].width,
              height: versions[version].height,
            })
            .background({
              r: 255,
              g: 255,
              b: 255,
            })
            .extend({
              // width: versions[version].width,                  // optional
              height: versions[version].height,
              gravity: {
                type: GravityType.CENTER, // required
              },
            })
            .build({
              path: `https://aws.zotzon.com/images/${id}/original.jpeg`,
              signature,
            })
        );
      case 'inst':
        return (
          getImageHost() +
          pb() // TODO: use suitable domain name according to env and hostname
            .format('webp')
            .minHeight(versions[version].height)
            .minWidth(versions[version].width)
            .resize({
              type: versions[version].type, // https://github.com/imgproxy/imgproxy/blob/6f292443eafb2e39f9252175b61faa6b38105a7c/docs/generating_the_url.md#resizing-type
              width: versions[version].width,
              height: versions[version].height,
            })
            .gravity({
              type: GravityType.CENTER,
            })

            .background({
              r: 255,
              g: 255,
              b: 255,
            })
            .build({
              path: `https://aws.zotzon.com/images/${id}/original.jpeg`,
              signature,
            })
        );
      case 'crop':
        return (
          getImageHost() +
          pb() // TODO: use suitable domain name according to env and hostname
            .format('webp')
            .minHeight(versions[version].height)
            .minWidth(versions[version].width)
            .gravity({
              type: GravityType.CENTER,
            })
            .resize({
              type: ResizeType.CROP,
              width: versions[version].width,
              height: versions[version].height,
            })
            // .crop({
            //   width: versions[version].width,
            //   height: versions[version].height,
            // })
            .background({
              r: 255,
              g: 255,
              b: 255,
            })
            .build({
              path: `https://aws.zotzon.com/images/${id}/original.jpeg`,
              signature,
            })
        );
      case 'inst_hor':
        return (
          getImageHost() +
          pb() // TODO: use suitable domain name according to env and hostname
            .format('webp')
            .minHeight(versions[version].height)
            .minWidth(versions[version].width)
            .resize({
              type: versions[version].type, // https://github.com/imgproxy/imgproxy/blob/6f292443eafb2e39f9252175b61faa6b38105a7c/docs/generating_the_url.md#resizing-type
              width: versions[version].width,
              height: versions[version].height,
            })
            .gravity({
              type: GravityType.CENTER,
            })

            .background({
              r: 255,
              g: 255,
              b: 255,
            })
            .build({
              path: `https://aws.zotzon.com/images/${id}/original.jpeg`,
              signature,
            })
        );
    }
  }
  return null;
};

const avatarVersions = {
  profile: {
    width: 100,
    height: 100,
    fit: 'fill-down',
  },
  small: {
    width: 40,
    height: 40,
    fit: 'fill-down',
  },
};

export const getAvatarVersionUrl = ({ url, version = 'small' }) => {
  if (url && version) {
    const path = getCorrectImageUrl(url);
    switch (version) {
      case 'original':
        return (
          getImageHost() +
          pb().build({
            path,
            signature,
          })
        );
      case 'profile':
      case 'small':
        return (
          getImageHost() +
          pb() // TODO: use suitable domain name according to env and hostname
            .resize({
              type: avatarVersions[version].type, // https://github.com/imgproxy/imgproxy/blob/6f292443eafb2e39f9252175b61faa6b38105a7c/docs/generating_the_url.md#resizing-type
              width: avatarVersions[version].width,
              height: avatarVersions[version].height,
            })
            .build({
              path,
              signature,
            })
        );
    }
  }
  return null;
};

export const getCorrectImageUrl = (url) => {
  if (url) {
    return url
      .replace('aws-zotzon-com-dtc1x9s8g594s8rdjthefcxhg99n4euw1a-s3alias.s3-eu-west-1.amazonaws.com', 'aws.zotzon.com')
      .replace('zotzon-img.s3-eu-west-1.amazonaws.com', 'aws.zotzon.com');
  }
  return null;
};
