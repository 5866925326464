import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Avatar, Dropdown, Menu } from 'antd';
import {
  DownOutlined,
  MenuOutlined,
  PlusOutlined,
  SearchOutlined,
  UserSwitchOutlined,
  SettingOutlined,
  LogoutOutlined,
  PlusCircleOutlined,
  WechatOutlined,
  CopyOutlined,
  BellOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Link from 'components/shared/elements/Link';
import css from '../../css/index.module.css';
import styles from 'components/ant/css/styles.module.css';
import {
  categoryPath,
  privateAccountsPath,
  privateAddListingPath,
  privateChatsPath,
  privateListingsPath,
  privateSettingsPath,
} from 'constants/path';
import { useRouter } from 'next/router';
import { useAuthContext } from 'components/shared/Auth';
import { useCategoriesContext } from 'components/shared/Categories';
import classNames from 'classnames';
import ZotzonAvatar from '../../../shared/elements/images/ZotzonAvatar';

const HeadMenu = ({ showSearch, headerRef, setShowSearch }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { isAuth, signOut, data } = useAuthContext();
  const [isLoggedIn, setLoggedIn] = useState(false);
  useEffect(() => setLoggedIn(isAuth), [isAuth]);
  const [showMenu, setShowMenu] = useState(false);
  const { categories } = useCategoriesContext();
  const [avatar, setAvatar] = React.useState();

  const clickMenu = (e) => {
    //console.log('click ', e);
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    return size;
  }

  const [width, height] = useWindowSize();
  const [menuPosition, setMenuPosition] = useState(300);

  const headMenu = useRef(null);

  // useEffect(() => {
  //   let left = headMenu.current.offsetLeft + headerRef.current.offsetLeft - 250;
  //   setMenuPosition(left);
  // }, [width]);

  const createList = (items) => {
    return items.map((item, index) => ({
      key: item.id,
      label: <Link href={categoryPath({ slug: item.slug })}>{router.locale === 'ru' ? item.nameRu : item.nameEn}</Link>,
      children: createList(item.subcategories),
    }));
  };

  const categoriesList = createList(categories);

  const languageItems = [
    {
      label: (
        <Link href={router.asPath} locale='ru'>
          Русский
        </Link>
      ),
      key: 0,
    },
    {
      label: (
        <Link href={router.asPath} locale='en'>
          English
        </Link>
      ),
      key: 1,
    },
    {
      label: (
        <Link href={router.asPath} locale='cn'>
          中国人
        </Link>
      ),
      key: 2,
    },
  ];

  const userMenuItems = [
    {
      key: 99,
      label: (
        <>
          <Link href={`/users/${data?.id ?? ''}`}>
            <Avatar icon={<UserOutlined />} size={20} style={{ marginRight: '6px' }} />
            {data?.firstName} {data?.lastName?.[0]}
          </Link>
        </>
      ),
    },
    {
      key: 1,
      label: (
        <>
          <PlusCircleOutlined /> <Link href={privateAddListingPath()}>{t('addListing')}</Link>
        </>
      ),
    },
    {
      key: 2,
      label: (
        <>
          <WechatOutlined /> <Link href={privateChatsPath()}>{t('Chats')}</Link>
        </>
      ),
    },
    {
      key: 3,
      label: (
        <Link href={privateAccountsPath()}>
          <UserSwitchOutlined /> {t('accounts')}
        </Link>
      ),
    },
    {
      key: 4,
      label: (
        <>
          <SettingOutlined /> <Link href={privateSettingsPath()}>{t('settings')}</Link>
        </>
      ),
    },
    {
      key: 5,
      label: (
        <>
          <CopyOutlined /> <Link href={privateListingsPath()}>{t('myAds')}</Link>
        </>
      ),
    },
    {
      key: 6,
      label: (
        <span
          onClick={() => {
            signOut();
          }}
        >
          <LogoutOutlined /> {t('signedOut')}
        </span>
      ),
    },
  ];

  return (
    <div className={styles.headNavi}>
      <div className={styles.headNaviMenu}>
        <a
          className={styles.antDropdownLink}
          onClick={() => {
            setShowSearch(!showSearch);
          }}
        >
          <SearchOutlined />
        </a>
      </div>

      <div className={styles.headNaviMenu}>
        <Dropdown menu={{ items: languageItems }}>
          <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
            {router.locale}
          </a>
        </Dropdown>
      </div>

      {isLoggedIn && (
        <div className={styles.headNaviMenu}>
          <BellOutlined />
        </div>
      )}

      {isLoggedIn && (
        <div className={classNames(css.onlyPc, css.headNaviMenu, styles.headPlus)}>
          <Link href={privateAddListingPath()}>
            <PlusOutlined />
          </Link>
        </div>
      )}

      <div className={styles.divPadding1}>
        {isLoggedIn && (
          <div className={css.onlyPc}>
            <Dropdown trigger='click' menu={{ items: userMenuItems }}>
              <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
                <>
                  {data?.firstName} {data?.lastName?.[0]} <DownOutlined />
                </>
              </a>
            </Dropdown>
          </div>
        )}
        {!isLoggedIn && (
          <Link href='/auth/sign-in'>
            <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M7.69486 2.46443e-05C5.2632 -0.00648697 3.12335 1.27793 1.90034 3.21839C1.84613 3.30467 1.90672 3.41862 2.00718 3.41862H3.12813C3.20467 3.41862 3.27642 3.38444 3.32426 3.32421C3.43587 3.18583 3.55546 3.05235 3.68143 2.92537C4.20125 2.3963 4.80557 1.97956 5.47847 1.68979C6.17368 1.39025 6.91354 1.23723 7.67732 1.23723C8.4411 1.23723 9.18096 1.38863 9.87618 1.68979C10.5491 1.97956 11.1534 2.3963 11.6732 2.92537C12.193 3.45444 12.5996 4.07141 12.8851 4.75676C13.18 5.46653 13.3283 6.22025 13.3283 7.00001C13.3283 7.77978 13.1784 8.5335 12.8851 9.24326C12.6012 9.92861 12.193 10.5456 11.6732 11.0747C11.1534 11.6037 10.5491 12.0205 9.87618 12.3102C9.18041 12.6103 8.4326 12.7642 7.67732 12.7628C6.91354 12.7628 6.17368 12.6098 5.47847 12.3102C4.80689 12.0208 4.19663 11.6012 3.68143 11.0747C3.55546 10.9461 3.43747 10.8126 3.32426 10.6758C3.27642 10.6156 3.20307 10.5814 3.12813 10.5814H2.00718C1.90672 10.5814 1.84453 10.6954 1.90034 10.7816C3.12175 12.7172 5.25204 14 7.67732 14C11.4436 14 14.5019 10.9054 14.5402 7.07164C14.5784 3.17607 11.5154 0.00979207 7.69486 2.46443e-05ZM5.67491 8.82327V7.58606H0.66809C0.59793 7.58606 0.540527 7.52745 0.540527 7.45583V6.5442C0.540527 6.47257 0.59793 6.41397 0.66809 6.41397H5.67491V5.17676C5.67491 5.06769 5.79928 5.00583 5.8822 5.0742L8.14484 6.89745C8.16008 6.90964 8.17241 6.9252 8.18089 6.94297C8.18937 6.96074 8.19378 6.98025 8.19378 7.00001C8.19378 7.01978 8.18937 7.03928 8.18089 7.05705C8.17241 7.07482 8.16008 7.09039 8.14484 7.10257L5.8822 8.92582C5.79928 8.99257 5.67491 8.93233 5.67491 8.82327Z'
                fill='#0C6191'
              />
            </svg>
          </Link>
        )}
      </div>
      <div className={styles.divPadding}>
        {!isLoggedIn && (
          <>
            <Link href='/auth/sign-up' className={styles.aLink}>
              {t('signUp')}
            </Link>
            <Link href='/auth/sign-in' className={classNames(styles.aLink, styles.aLinkMarginColor)}>
              {t('signIn')}
            </Link>
          </>
        )}
      </div>
      {/*<div className={styles.divPadding2} ref={headMenu}>*/}
      {/*  <MenuOutlined*/}
      {/*    className={styles.menuOutlined}*/}
      {/*    onClick={() => {*/}
      {/*      setShowMenu(!showMenu);*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div></div>*/}
      <div
        className='headerMenuBox'
        style={{
          left: `${menuPosition}px`,
          display: `${showMenu ? 'inline' : 'none'}`,
        }}
      >
        {/*<Menu*/}
        {/*  id='-headMenu'*/}
        {/*  onClick={clickMenu}*/}
        {/*  defaultSelectedKeys={['category']}*/}
        {/*  defaultOpenKeys={['category']}*/}
        {/*  mode='inline'*/}
        {/*  className={styles.menuWidth}*/}
        {/*  items={categoriesList}*/}
        {/*/>*/}
        {/* <SubMenu key='category' title={t('common:category')}>
            {categoriesList}
          </SubMenu>
        </Menu> */}
      </div>
    </div>
  );
};

export default HeadMenu;
